<template>
  <div class="page-main-box pt-24 pl-24 pr-24">
    <ul class="list">
      <!-- 基础信息 -->
      <li class="card">
        <p class="title">基础信息</p>
        <ul class="info-list">
          <li>
            <span>订单金额：</span>{{ (orderInfo.amount / 100).toFixed(2) }}
          </li>
          <li>
            <span>应付金额：</span
            >{{
              orderInfo.payable_amount
                ? (orderInfo.payable_amount / 100).toFixed(2)
                : "-"
            }}
          </li>
          <li>
            <span>实付金额：</span
            >{{ (orderInfo.payment_amount / 100).toFixed(2) }}
          </li>
          <li><span>订单编号：</span>{{ orderInfo.order_no }}</li>
          <li><span>油卡卡号：</span>{{ orderInfo.card_no }}</li>
          <li><span>订单状态：</span>{{ orderInfo.status_desc }}</li>
          <li>
            <span>创建时间：</span
            >{{
              orderInfo.order_time &&
                formatToDateTime(orderInfo.order_time, "yyyy-MM-dd hh:mm:ss")
            }}
          </li>
          <li><span>创建人：</span>{{ orderInfo.station_name }}</li>
        </ul>
      </li>
      <!-- 司机信息 -->
      <li class="card">
        <p class="title">司机信息</p>
        <ul class="info-list">
          <li><span>司机姓名：</span>{{ orderInfo.driver_name }}</li>
          <li><span>手机号：</span>{{ orderInfo.driver_mobile }}</li>
          <li><span>车牌号：</span>{{ orderInfo.plate_number }}</li>
          <li><span>开票企业：</span>{{ orderInfo.company_name }}</li>
        </ul>
      </li>
      <!-- 加油/气信息 -->
      <li class="card">
        <p class="title">加油/气信息</p>
        <ul class="info-list">
          <li><span>油品：</span>{{ orderInfo.oil_name }}</li>
          <li><span>销售价：</span>{{ (orderInfo.price / 100).toFixed(2) }}</li>
          <li><span>加油/气量：</span>{{ orderInfo.quantity }}</li>
        </ul>
      </li>
      <!-- 加油站信息 -->
      <li class="card">
        <p class="title">加油站信息</p>
        <ul class="info-list">
          <li><span>加油站名称：</span>{{ orderInfo.station_name }}</li>
          <li><span>加油站编号：</span>{{ orderInfo.station_no }}</li>
          <li><span>地区：</span>{{ orderInfo.area }}</li>
          <li><span>加油枪号：</span>{{ orderInfo.gun_no }}</li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useGasOrderDetailApi } from "@/apis/oil";
import $await from "@/utils/await";
import { formatToDateTime } from "@wlhy-web-lib/shared";

export default {
  name: "consumer-orders-detail",
  components: {},
  setup() {
    const route = useRoute();
    const gasOrderDetailApi = useGasOrderDetailApi();

    const orderInfo = ref({});
    (async () => {
      let [, res] = await $await(
        gasOrderDetailApi({
          id: route.query.id
        })
      );
      if (res) {
        orderInfo.value = res;
      }
    })();

    return { orderInfo, formatToDateTime };
  }
};
</script>

<style lang="less" scoped>
.page-main-box {
  background: #f5f5f5;
  .list {
    .card {
      margin-bottom: 16px;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
      background: #ffffff;
      border-radius: 4px;
      overflow: hidden;
      .title {
        border-left: 4px solid #007aff;
        height: 22px;
        line-height: 22px;
        font-size: 500;
        font-weight: 600;
        text-indent: 16px;
        margin-top: 16px;
      }
      .info-list {
        margin-left: 24px;
        overflow: hidden;
        margin-top: 24px;
        li {
          width: 30%;
          float: left;
          margin-bottom: 16px;
          span {
            color: #808080;
          }
        }
      }
    }
  }
}
</style>
